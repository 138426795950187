import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.min.css';
import 'prismjs/components/prism-python';
import PropTypes from 'prop-types';

export default function Code({ language, code, inline }) {
    useEffect(() => {
        Prism.highlightAll();
    }, [code]);

    let content;
    if (inline) {
        content = <code className={`language-${language}`}>{code}</code>;
    } else {
        content = (
            <pre style={{ whiteSpace: 'pre-line', overflow: 'auto', wordBreak: 'break-word' }} >
                <code style={{ whiteSpace: 'pre-line', overflow: 'auto', wordBreak: 'break-word' }} className={`language-${language}`}>{code}</code>
            </pre>
        );
    }
    return content;
}

Code.propTypes = {
    language: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    inline: PropTypes.bool
};
