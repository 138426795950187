import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Background = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    color: theme.palette.text.main
}));

// These should probably be fetched from some database
const orgNameToLogo = {
    ericsson: '/ericsson_logo.svg',
    ess: '/ess_logo.svg',
    'schneider-electric': '/schneider_logo.png'
};

const getLogo = (orgName) => orgNameToLogo[orgName.toLowerCase()];

const printOrgName = (orgName) => orgName.replaceAll('-', ' ');

export { Background, getLogo, printOrgName };
