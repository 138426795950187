import { React, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import { Link } from 'react-router-dom';

// import Dialog from '@mui/material/Dialog';

// import Typography from '@mui/material/Typography';
// import Card from '@mui/material/Card';
import { Button, Typography, Card, Box, Dialog, Paper, CircularProgress, ButtonGroup } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import Grid from '@mui/material/Grid';

import LoadingButton from '@mui/lab/LoadingButton';

import { styled } from '@mui/material/styles';

import styles from './JupyterLink.module.css';

import {
    useGetDatasetsQuery,
} from '../../state/apiSlice';
import config from '../../config/config.json';
import Code from '../codeHighlighting';
import { generateToken } from '../../lib/apiRequests';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.main
}));

const codeListDataFromDs = (ds) => `from dataportal import DataportalClient;

token = '' # Enter your generated JWT

client = DataportalClient(token)
client.fromDataset('${ds.DatasetName}').listFiles()
`;

const initialDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date;
};

function getExpiryStringFromDate(future) {
    try {
        const today = new Date();
        const diffTime = future - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 0) {
            return `${diffDays}d`;
        } else {
            return null;
        }
    } catch (er) {
        return null;
    }
}

export default function JupyterLink() {
    const {
        data: datasets,
        isSuccess,
        isError,
        error
    } = useGetDatasetsQuery();

    const [open, setOpen] = useState(false);
    const [expire, setExpire] = useState(initialDate());
    const [myToken, setMyToken] = useState();

    const [fetchingToken, setFetchingToken] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search);
    const gentokendialog = queryParameters.get('gentokendialog');

    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);

    const toggleDialog = () => {
        if (!open) {
            setMyToken();
        }
        setOpen(!open);
    };

    async function setToken() {
        setFetchingToken(true);
        const diff = getExpiryStringFromDate(expire);
        if (diff) {
            await generateToken(expire).then((res) => {
                setMyToken(res.data.access_token);
            }).catch(console.err);
        } else {
            alert('something went wrong, was your expiry date between 1-365 days in the future?');
        }
        setFetchingToken(false);
    }

    useEffect(() => {
        if (gentokendialog && !open) {
            toggleDialog();
        }
    }, []);

    const selectedID = useSelector(state => state.dataset.selectedID);

    let content = <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
        <CircularProgress sx={{ m: 2 }} />
        <Typography align='center'>
            {'Fetching datasets...'}
        </Typography>
    </div>;
    if (isSuccess) {
        let ds;
        if (datasets.filtered.length) {
            ds = datasets.filtered.find(ds => ds.DatasetID === selectedID);
        } else {
            ds = { DatasetName: 'SomeCoolData' };
        }
        if (ds) {
            content = <>
                <Typography variant='body1'>
                    <b>Loading data:</b> The jupyter environment comes preloaded with a python module for loading data from the dataportal into pandas dataframes.
                    First, a token must be generated to allow the library to access the dataportal with your credentials. Press the button below to open the token
                    generation page, create a token and save it to your computer. To then, e.g., list the available files from the dataset {<i>{ds.DatasetName}</i>},
                    simply run
                </Typography>
                <Code language='python' code={codeListDataFromDs(ds)} />
                <Typography variant='body1' paragraph>
                    Run <Code language='python' inline={true} code='help(DataportalClient)'/> to get the full list of features, or visit
                    the <Code language='python' inline={true} code='introduction.ipynb'/> notebook in your Jupyterlab environment.
                </Typography>
            </>;
        }
    } else if (isError) {
        console.error('Failed to fetch datasets: ' + error);
    }

    return <>
        <Typography variant='h4' gutterBottom>
            Access the data files via JupyterLab
        </Typography>
        <Card className={styles.expcard}>
            <Typography variant='body1' paragraph>
                <b>About:</b> WARA-Ops hosts a Juyterhub instance in ERDC to allow users to view and experiment on the datasets that they have access to.
                Press the button below to visit it, a new login window will appear where you can use the same credentials as for this dataportal.
                After logging in a server will be spawned for you that serves a Jupyterlab environment, in where you can write and run notebook and other code
                directly in ERDC. The server will be terminated after 24 hours of idle time, but data saved will persist.
                Some default notebooks are present in the environment, check them out to see how you can get started.
            </Typography>
            {content}
            <Typography variant='body1' paragraph>
                <b>Memory limits:</b> Each Jupyterlab server is granted an upper limit of 64G memory. However, if using more than 16G your server has a high
                probability to get evicted in the unlikely event that the underlying machine runs out of memory. If you plan on running memory
                intensive jobs over longer periods of time, saving your results often becomes even more important.
            </Typography>
            <Typography variant='body1'>
                <b>Updates:</b> The dataportal, Jupyterlab environment and dataportal client are regularly updated.
                Some of these updates can cause old versions of the dataportal client to break.
                As any running Jupyterlab environments will keep its old client version until restarted,
                you could experience that the dataportal client suddenly stops working properly.
                To solve this, simply stop your server (<i>File -{'>'} Hub Control Panel -{'>'} Stop My Server</i>) and starting it again.
                If any errors persist, please contact the support.
            </Typography>
        </Card>
        <Button onClick={toggleDialog} variant="outlined" size="large" sx={{ margin: 2 }}>
            Generate Token
        </Button>
        <Button href={config.jhub.url} rel="noopener noreferrer" target="_blank" variant="outlined" size="large" sx={{ margin: 2 }}>
            To Jupyterhub!
        </Button>
        <Dialog open={open} onClose={toggleDialog}>
            <div className={styles['dialog-frame']}>
                <h2>Generate new JWT</h2>
                <Box sx={{ m: 1 }}>
                    Select an expiration date (default 1 month):
                </Box>
                <Calendar
                    selectRange={false}
                    onChange={setExpire}
                    minDate={new Date()}
                    maxDate={nextYear}
                    minDetail='decade'
                    value={expire}
                    className={styles.calendar}
                    />
                <div className={styles['login-button-group']}>
                    <ButtonGroup sx={{ m: 1 }}>
                        <LoadingButton loading={fetchingToken} onClick={setToken}>Create</LoadingButton>
                        <CopyToClipboard text={myToken}>
                            <Button disabled={!myToken || fetchingToken} onClick={() => setOpen(true)}>Copy</Button>
                        </CopyToClipboard>
                        <Button disabled={fetchingToken} onClick={toggleDialog}>Cancel</Button>
                    </ButtonGroup>
                </div>
                {myToken
                    ? <Item className={styles['jwt-access-token']}>
                        {myToken}
                    </Item>
                    : <></>}
            </div>
        </Dialog>
    </>;
}
